<script setup lang="ts">
import { onMounted, ref } from 'vue'

const model = defineModel<string|number|null>({ required: true })

const input = ref<HTMLInputElement | null>(null)

onMounted(() => {
  if (input.value?.hasAttribute('autofocus')) {
    input.value?.focus()
  }
})

defineExpose({ focus: () => input.value?.focus() })
</script>

<template>
  <input
    class="border-gray-600 bg-gray-700 placeholder-gray-500 text-gray-300 focus:border-primary-600 focus:ring-primary-600 shadow-sm py-4 px-4"
    v-model="model"
    ref="input"
  />
</template>
